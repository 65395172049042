import React from 'react';
import './alert.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const alertVariants = {
  hidden: {
    opacity: 0,
    y: '-100vh',
    overflow: 'hidden',
    position: 'fixed',
    height: 0,
    top: 0,
    right: 0,
    left: 0,
    zIndex: 5000,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.2 },
    height: 'auto',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 5000,
  },
  exit: {
    y: '-100vh',
    transition: { ease: 'easeInOut' },
    height: 0,
    right: 0,
    left: 0,
    position: 'fixed',
    top: 0,
    zIndex: 5000,
  },
};

const AlertComponent = (props) => {
  const { alertMessage, onSuccess, onCancel, showButtons } = props;
  const { t } = useTranslation();

  return (
    <motion.div
      className="alert-modal"
      variants={alertVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="alert alert-danger alert-dismissible">
        <p>{alertMessage}</p>
        {showButtons && (
          <>
            <button type="button" className="btn btn-light" onClick={onSuccess}>
              {t('alert_confirmation_button')}
            </button>
            <button type="button" className="btn btn-cancel" onClick={onCancel}>
              {t('alert_cancel_button')}
            </button>
          </>
        )}
      </div>
      {!showButtons && (
        <i
          className="far fa-times-circle close alert-close"
          onClick={onCancel}
        ></i>
      )}
    </motion.div>
  );
};

AlertComponent.defaultProps = {
  alertMessage: '',
  onSuccess: () => {},
  onCancel: () => {},
  showButtons: false,
};

AlertComponent.propTypes = {
  alertMessage: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showButtons: PropTypes.bool.isRequired,
};

export default AlertComponent;
