import { THEMES_TYPE } from '../helpers/constants';
import ConcreteTheme from './concreteTheme';
import getMyCustomerVoiceTheme from './myCustomerVoice.theme';
import getMyTeamInsightsTheme from './myTeamInsights.theme';
import getLinkedinTheme from './linkedin.theme';

export default function getTheme(themeType) {
  switch (themeType) {
    case THEMES_TYPE.myCustomerVoice:
      return getMyCustomerVoiceTheme();
    case THEMES_TYPE.myTeamInsights:
      return getMyTeamInsightsTheme();
    case THEMES_TYPE.linkedin:
      return getLinkedinTheme();
    default:
      return new ConcreteTheme();
  }
}
