import {
  SAVE_SURVEY,
  SAVE_REVIEW,
  SAVE_PENDING_PHOTOS,
  CLEAR_SURVEY,
} from '../actions/actionTypes';

const initialState = {
  survey: null,
  pendingPhotos: [],
  review: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SURVEY:
      return {
        ...state,
        ...{ survey: action.survey },
      };
    case SAVE_REVIEW:
      return {
        ...state,
        ...{ review: action.review },
      };
    case SAVE_PENDING_PHOTOS:
      return {
        ...state,
        ...{ pendingPhotos: action.pendingPhotos },
      };
    case CLEAR_SURVEY:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
