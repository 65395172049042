// errors
export const SET_ERROR = 'SET_ERROR';
export const SET_ERROR_SAGA = 'SET_ERROR_SAGA';
export const CLEAR_ERROR = 'CLEAR_ERROR';
// loadings
export const SET_IS_LOADING_LOGIN = 'SET_IS_LOADING_LOGIN';
// connectivity
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_PROCESSES = 'SET_PROCESSES';
export const QUEUE_THE_WELCOME_PROCESSES = 'QUEUE_THE_WELCOME_PROCESSES';
export const QUEUE_THE_UPLOAD_PROCESSES = 'QUEUE_THE_UPLOAD_PROCESSES';
export const QUEUE_THE_FOLLOW_UP_PROCESSES = 'QUEUE_THE_FOLLOW_UP_PROCESSES';
export const CLEAR_PROCESSES = 'CLEAR_PROCESSES';
// auth
export const LOGIN = 'LOGIN';
export const SAVE_TOKENS = 'SAVE_TOKENS';
export const CLEAR_TOKENS = 'CLEAR_TOKENS';
// survey
export const GET_SURVEY = 'GET_SURVEY';
export const SAVE_SURVEY = 'SAVE_SURVEY';
export const SAVE_REVIEW = 'SAVE_REVIEW';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const CLEAR_SURVEY = 'CLEAR_SURVEY';
export const SAVE_PENDING_PHOTOS = 'SAVE_PENDING_PHOTOS';
// theme
export const SET_THEME_DATA = 'SET_THEME_DATA';
