import ConcreteTheme from './concreteTheme';
import {
  DEFAULT_THEME_THANKS_IMAGE,
  DEFAULT_UPLOADING_GIF,
  DEFAULT_THEME_LOGO,
  DEFAULT_THEME_FAV_ICON,
} from '../helpers/assetsConstants';
import colors from '../helpers/colors';
import i18next from '../react-i18next';

const {
  linkedinThemeBackgroundColor,
  linkedinPrimaryColor,
  linkedinQuestionsScreenTitleColor,
  linkedinQuestionsScreenSuggestionBoxBackgroundColor,
  linkedinQuestionsScreenSuggestionTitleColor,
} = colors;

const theme = () =>
  new ConcreteTheme(
    DEFAULT_THEME_FAV_ICON,
    linkedinThemeBackgroundColor,
    DEFAULT_THEME_LOGO,
    linkedinPrimaryColor,
    i18next.t('default_theme_welcome_title'),
    linkedinQuestionsScreenTitleColor,
    i18next.t('default_theme_add_details_button'),
    i18next.t('linkedin_theme_suggestion_title'),
    linkedinQuestionsScreenSuggestionBoxBackgroundColor,
    linkedinQuestionsScreenSuggestionTitleColor,
    i18next.t('default_theme_suggestion_placeholder'),
    DEFAULT_UPLOADING_GIF,
    DEFAULT_THEME_THANKS_IMAGE,
    i18next.t('default_theme_thanks_part2'),
    i18next.t('default_theme_follow_up_button'),
    i18next.t('default_theme_followup_part1'),
    i18next.t('default_theme_followup_part2')
  );

export default theme;
