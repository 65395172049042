import React, { useEffect, useRef, useState } from 'react';
import RatingStars from './ratingStars';
import { StyleRoot } from 'radium';
import './ratingCard.css';
import Collapsible from './collapsible';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const RatingCard = (props) => {
  const {
    activeQuestion,
    setActiveQuestion,
    id,
    name,
    detailsButtonText,
    onAddComment,
    images,
    onAddPhotos,
    onRemoveImage,
    onRating,
    convertFiles,
  } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [itHasComment, setItHasComment] = useState(false);
  const [rating, setRating] = useState(0);

  let inputFileRef = useRef(null);

  useEffect(() => {
    if (activeQuestion !== id) setIsOpen(false);
  }, [activeQuestion, id]);

  const handleDetailsOpening = () => {
    setActiveQuestion(id);
    setIsOpen(true);
  };

  const onCommentChange = (event) => {
    if (event.target.value && event.target.value.trim() !== '') {
      setItHasComment(true);
    } else {
      setItHasComment(false);
    }
    onAddComment(event.target.value, id);
  };

  const onRatingChange = (numberOfStars) => {
    setRating(numberOfStars);
    onRating(numberOfStars, id);
  };

  const filesSelectedHandler = async (event) => {
    const newImages = await convertFiles(event);
    onAddPhotos([...images, ...newImages], id);
  };

  return (
    <>
      <StyleRoot>
        <div>
          <div className="row m-btn">
            <div className="col-12 rating-card text-center">
              <h3>{name}</h3>
              <div className="stars">
                <RatingStars {...{ onRatingChange, rating }} />
              </div>
            </div>
          </div>
          <Collapsible isOpen={isOpen}>
            <div className="row m-0-btn-30">
              <div className="col-12 pad-0">
                <textarea
                  placeholder={t('add_comment_placeholder')}
                  className="form-control"
                  id=""
                  rows="3"
                  maxLength="2000"
                  onChange={onCommentChange}
                ></textarea>
              </div>
              <div>
                <input
                  className="input-file"
                  ref={(inputFile) => (inputFileRef = inputFile)}
                  type="file"
                  accept="image/jpeg"
                  onChange={(event) => filesSelectedHandler(event)}
                  multiple
                />
                <div className="col-12 pad-0">
                  <h5>{t('photos_title')}</h5>
                  <div className="content-box gallery">
                    <div className="images">
                      {images.length > 0 &&
                        images.map((image, index) => {
                          return (
                            <div className="image" key={index.toString()}>
                              <i
                                className="far fa-times-circle close"
                                onClick={(event) => onRemoveImage(id, index)}
                              ></i>
                              <img src={image.imageUrl} alt="" />
                            </div>
                          );
                        })}
                    </div>
                    <div className="photo" onClick={() => inputFileRef.click()}>
                      <i className="fas fa-camera"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
        {!isOpen && id !== activeQuestion && (
          <div className={`row m-btn ${rating > 0 ? '' : 'detail-inactive'}`}>
            <div className="col-8 col-sm-9 col-md-9 pad-0">
              <button
                type="button"
                className="btn btn-dark w-100"
                onClick={handleDetailsOpening}
              >
                {detailsButtonText}
              </button>
            </div>
            <div className="col-4 col-sm-3 col-md-3 pad-0">
              <div className="f-right">
                <button
                  type="button"
                  className="btn btn-secondary position-relative mr-rem"
                  onClick={handleDetailsOpening}
                >
                  <i className="fas fa-comment-dots"></i>
                  {itHasComment && (
                    <span className="position-absolute badge rounded-pill badge-green">
                      <i className="fas fa-check"></i>
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary position-relative f-right"
                  onClick={handleDetailsOpening}
                >
                  <i className="fas fa-camera"></i>
                  <span className="position-absolute badge rounded-pill bg-danger">
                    {images.length}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </StyleRoot>
    </>
  );
};

RatingCard.defaultProps = {
  activeQuestion: 0,
  setActiveQuestion: () => {},
  id: '0',
  name: '',
  onAddComment: () => {},
  images: [],
  onAddPhotos: () => {},
  onRemoveImage: () => {},
  onRating: () => {},
};

RatingCard.propTypes = {
  activeQuestion: PropTypes.any.isRequired,
  setActiveQuestion: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  detailsButtonText: PropTypes.string.isRequired,
  onAddComment: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  onAddPhotos: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  onRating: PropTypes.func.isRequired,
  convertFiles: PropTypes.func.isRequired,
};

export default RatingCard;
