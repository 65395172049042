import {
  SET_CONNECTION_STATUS,
  SET_PROCESSES,
  CLEAR_PROCESSES,
} from '../actions/actionTypes';

const initialState = {
  isOnline: true,
  processes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONNECTION_STATUS:
      return {
        ...state,
        ...{ isOnline: action.isOnline },
      };
    case SET_PROCESSES:
      return {
        ...state,
        ...{ processes: action.processes },
      };
    case CLEAR_PROCESSES:
      return {
        ...state,
        ...{ processes: [] },
      };
    default:
      return state;
  }
};

export default reducer;
