import { all, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import * as actions from '../actions/rootActions';

function* setErrorSaga(action) {
  try {
    yield put(actions.setErrorSucceeded(action.errorMessage));
  } catch (error) {
    console.log('Error: ', error);
  }
}

export default function* errorSaga() {
  yield all([takeLatest(actionTypes.SET_ERROR_SAGA, setErrorSaga)]);
}
