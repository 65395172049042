import ConcreteTheme from './concreteTheme';
import {
  DEFAULT_THEME_THANKS_IMAGE,
  DEFAULT_UPLOADING_GIF,
  DEFAULT_THEME_LOGO,
  DEFAULT_THEME_FAV_ICON,
} from '../helpers/assetsConstants';
import colors from '../helpers/colors';
import i18next from '../react-i18next';

const {
  defaultBackgroundColor,
  defaultPrimaryColor,
  defaultQuestionsScreenTitleColor,
  defaultQuestionsScreenSuggestionBoxBackgroundColor,
  defaultQuestionsScreenSuggestionTitleColor,
} = colors;

const theme = () =>
  new ConcreteTheme(
    DEFAULT_THEME_FAV_ICON,
    defaultBackgroundColor,
    DEFAULT_THEME_LOGO,
    defaultPrimaryColor,
    i18next.t('default_theme_welcome_title'),
    defaultQuestionsScreenTitleColor,
    i18next.t('default_theme_add_details_button'),
    i18next.t('my_customer_voice_theme_suggestion_title'),
    defaultQuestionsScreenSuggestionBoxBackgroundColor,
    defaultQuestionsScreenSuggestionTitleColor,
    i18next.t('default_theme_suggestion_placeholder'),
    DEFAULT_UPLOADING_GIF,
    DEFAULT_THEME_THANKS_IMAGE,
    i18next.t('default_theme_thanks_part2'),
    i18next.t('default_theme_follow_up_button'),
    i18next.t('default_theme_followup_part1'),
    i18next.t('default_theme_followup_part2')
  );

export default theme;
