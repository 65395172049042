import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/rootActions';
import * as actionTypes from '../actions/actionTypes';
import ProcessesEnum from '../../helpers/processesEnum';

function* queueTheWelcomeProcessesSaga(action) {
  try {
    const token = yield select((state) => state.auth.token);
    const survey = yield select((state) => state.survey.survey);
    if (!token) {
      yield put(
        actions.setProcesses([ProcessesEnum.login, ProcessesEnum.getSurvey])
      );
    } else {
      if (!survey) {
        yield put(actions.setProcesses([ProcessesEnum.getSurvey]));
      }
    }
  } catch (error) {
    console.log('Error: ', error);
  }
}

function* queueTheUploadProcessesSaga(action) {
  try {
    const review = yield select((state) => state.survey.review);
    if (!review.survey_id) {
      yield put(
        actions.setProcesses([
          ProcessesEnum.submitReview,
          ProcessesEnum.uploadPhotos,
        ])
      );
    } else {
      yield put(actions.setProcesses([ProcessesEnum.uploadPhotos]));
    }
    if (action.pendingPhotos.length > 0) {
      yield put(actions.savePendingPhotos(action.pendingPhotos));
    }
  } catch (error) {
    console.log('Error: ', error);
  }
}

function* queueTheFollowUpProcessesSaga(action) {
  try {
    if (action.status === 'LOADING') {
      yield put(actions.setProcesses([ProcessesEnum.requestFollowUp]));
    }
  } catch (error) {
    console.log('Error: ', error);
  }
}

export default function* connectivitySaga() {
  yield all([
    takeLatest(
      actionTypes.QUEUE_THE_WELCOME_PROCESSES,
      queueTheWelcomeProcessesSaga
    ),
    takeLatest(
      actionTypes.QUEUE_THE_UPLOAD_PROCESSES,
      queueTheUploadProcessesSaga
    ),
    takeLatest(
      actionTypes.QUEUE_THE_FOLLOW_UP_PROCESSES,
      queueTheFollowUpProcessesSaga
    ),
  ]);
}
