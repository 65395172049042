import getTheme from '../../themes';
import { SET_THEME_DATA } from '../actions/actionTypes';
import { THEMES_TYPE } from '../../helpers/constants';

const initialState = {
  themeData: getTheme(),
  themeType: THEMES_TYPE.myCustomerVoice,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  const { type, themeType } = action;
  const newTheme = themeType || state.themeType;

  switch (type) {
    case SET_THEME_DATA:
      return {
        ...state,
        ...{ themeData: getTheme(newTheme), themeType: newTheme },
      };
    default:
      return state;
  }
};
