import * as actionTypes from './actionTypes';

export const getSurvey = () => {
  return {
    type: actionTypes.GET_SURVEY,
  };
};

export const saveSurvey = (survey) => {
  return {
    type: actionTypes.SAVE_SURVEY,
    survey: survey,
  };
};

export const saveReview = (review) => {
  return {
    type: actionTypes.SAVE_REVIEW,
    review: review,
  };
};

export const submitReview = () => {
  return {
    type: actionTypes.SUBMIT_REVIEW,
  };
};

export const savePendingPhotos = (pendingPhotos) => {
  return {
    type: actionTypes.SAVE_PENDING_PHOTOS,
    pendingPhotos: pendingPhotos,
  };
};

export const clearSurvey = () => {
  return {
    type: actionTypes.CLEAR_SURVEY,
  };
};
