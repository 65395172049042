import { all, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/rootActions';
import * as actionTypes from '../actions/actionTypes';
import { browserHistory } from '../../index';
import ApiService from '../../services/apiService';

function* loginSaga(action) {
  try {
    yield put(actions.clearTokens());
    yield put(actions.clearSurvey());
    const response = yield ApiService.post(
      'guest',
      JSON.stringify({ token: action.token })
    )
      .then((res) => {
        return {
          jwtToken: res.headers.authorization,
          refreshToken: res.headers.refresh_token,
          expiration: res.headers.token_expiration,
        };
      })
      .catch((error) => {
        console.log(error);
      });

    if (response) {
      yield put(
        actions.saveTokens(
          response.jwtToken,
          response.expiration,
          response.refreshToken
        )
      );
      yield put(actions.getSurvey());
    }
  } catch (error) {
    console.log('Error: ', error);
    browserHistory.push('/error');
  }
}

export default function* authSaga() {
  yield all([takeLatest(actionTypes.LOGIN, loginSaga)]);
}
