import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
// reducers
import authReducer from './reducers/authReducer';
import errorReducer from './reducers/errorReducer';
import surveyReducer from './reducers/surveyReducer';
import loadingReducer from './reducers/loadingReducer';
import connectivityReducer from './reducers/connectivityReducer';
import themeReducer from './reducers/themeReducer';
// sagas
import authSaga from './sagas/authSaga';
import errorSaga from './sagas/errorSaga';
import surveySaga from './sagas/surveySaga';
import connectivitySaga from './sagas/connectivitySaga';

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['error', 'loading', 'connectivity'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  survey: surveyReducer,
  loading: loadingReducer,
  connectivity: connectivityReducer,
  theme: themeReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(authSaga);
sagaMiddleware.run(errorSaga);
sagaMiddleware.run(surveySaga);
sagaMiddleware.run(connectivitySaga);

const storePersistor = persistStore(store);

export { store, storePersistor };
