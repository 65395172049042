import * as actionTypes from './actionTypes';

export const setConnectionStatus = (status) => {
  return {
    type: actionTypes.SET_CONNECTION_STATUS,
    isOnline: status,
  };
};

export const setProcesses = (processes) => {
  return {
    type: actionTypes.SET_PROCESSES,
    processes: processes,
  };
};

export const queueTheWelcomeProcesses = (token) => {
  return {
    type: actionTypes.QUEUE_THE_WELCOME_PROCESSES,
    token: token,
  };
};

export const queueTheUploadProcesses = (pendingPhotos) => {
  return {
    type: actionTypes.QUEUE_THE_UPLOAD_PROCESSES,
    pendingPhotos: pendingPhotos,
  };
};

export const queueTheFollowUpProcesses = (status) => {
  return {
    type: actionTypes.QUEUE_THE_FOLLOW_UP_PROCESSES,
    status: status,
  };
};

export const clearProcesses = () => {
  return {
    type: actionTypes.CLEAR_PROCESSES,
  };
};
