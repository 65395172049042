const generateRandomHash = (length) => {
  const hashLength = length || 25;
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
  const charactersLength = characters.length;
  for (let i = 0; i < hashLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  result = Date.now() + '-' + result;
  return result;
};

export default generateRandomHash;
