import EnglishTranslation from './en';
import SpanishTranslation from './es';

const getFormattedTranslation = (translation) => ({ translation });

const SUPPORTED_LANGUAGES = {
  ENGLISH: 'en',
  SPANISH: 'es',
};

const translations = {
  [SUPPORTED_LANGUAGES.ENGLISH]: getFormattedTranslation(EnglishTranslation),
  [SUPPORTED_LANGUAGES.SPANISH]: getFormattedTranslation(SpanishTranslation),
};

export { SUPPORTED_LANGUAGES, translations as default };
