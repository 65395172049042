import Compressor from 'compressorjs';
import ApiService from './apiService';
import { store } from '../store/store';
import { browserHistory } from '../index';

const submit = async (survey) => {
  const token = await store.getState().auth.token;
  if (token) {
    return await ApiService.post('survey', survey, {
      headers: { Authorization: token },
    })
      .then((response) => {
        return response.data.reviewId;
      })
      .catch(() => {
        const isOnline = store.getState().connectivity.isOnline;
        if (isOnline) {
          browserHistory.push('/error');
        } else {
          return null;
        }
      });
  } else {
    browserHistory.push('/error');
  }
};

const submitPhoto = async (reviewId, surveyPhoto) => {
  const token = await store.getState().auth.token;
  if (token) {
    const image = new FormData();
    const compressedImage = await compressImage(surveyPhoto.photo);
    image.append('image', compressedImage);
    image.append('imageHash', surveyPhoto.imageHash);
    return await ApiService.post(
      `survey/review/${reviewId}/question/${surveyPhoto.question_id}/images`,
      image,
      {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data',
          mimeType: 'multipart/form-data',
        },
      }
    )
      .then((response) => {
        return null;
      })
      .catch(() => surveyPhoto);
  } else {
    browserHistory.push('/error');
    return surveyPhoto;
  }
};

const compressImage = async (file) => {
  return new Promise((resolve, reject) => {
    return new Compressor(file, {
      strict: true,
      maxHeight: 720,
      maxWidth: 1280,
      quality: 0.8,
      success: (result) => {
        resolve(result);
      },
      error: (error) => reject(error),
    });
  });
};

const registerFollowUp = async (reviewId, email, firstName, phoneNumber) => {
  const token = await store.getState().auth.token;
  if (token) {
    return await ApiService.post(
      `survey/review/${reviewId}/follow-up`,
      {
        first_name: firstName,
        phone_number: phoneNumber,
        email,
      },
      { headers: { Authorization: token } }
    )
      .then((response) => {
        return null;
      })
      .catch((error) => {
        console.log(error);
        const isOnline = store.getState().connectivity.isOnline;
        if (isOnline) {
          return error.message;
        } else {
          return 'Connectivity Issue';
        }
      });
  } else {
    browserHistory.push('/error');
  }
};

export { submit, submitPhoto, registerFollowUp };
