import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store, storePersistor } from './store/store';
import * as assetsConstants from './helpers/assetsConstants';
import { PersistGate } from 'redux-persist/integration/react';
import ConnectivityChecker from './hoc/connectivityChecker/connectivityChecker';
import './react-i18next';

export const AssetsConstants = assetsConstants;

// const cacheImages = async () => {
//   const promises = Object.entries(AssetsConstants).map(([key, value]) => {
//     return new Promise(function (resolve, reject) {
//       const image = new Image();
//       image.src = value;
//       image.onload = resolve();
//       image.onerror = reject();
//     })
//   });
//   await Promise.all(promises);
// }

// cacheImages();

export const browserHistory = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={storePersistor}>
      <Router history={browserHistory}>
        <ConnectivityChecker />
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
