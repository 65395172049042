import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const screenVariants = {
  hidden: {
    opacity: 0,
    x: '100vw',
    height: 0,
    overflow: 'hidden',
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: 'spring', delay: 0.1 },
    height: '100%',
    overflow: 'visible',
  },
  exit: {
    x: '-100vh',
    transition: { ease: 'easeInOut' },
    height: 0,
    overflow: 'hidden',
  },
};

const MotionWrapper = ({ children }) => {
  return (
    <motion.div
      className="motionWrapper"
      variants={screenVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {children}
    </motion.div>
  );
};

MotionWrapper.defaultProps = {
  children: {},
};

MotionWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MotionWrapper;
