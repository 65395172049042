import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from './store/store';
import * as actions from './store/actions/rootActions';
import './App.css';
import { getThemeData } from './store/selectors/themeSelector';
import { AnimatePresence } from 'framer-motion';
import ErrorScreen from './components/error/errorScreen';
import SurveyScreen from './components/survey/surveyScreen';
import UploadScreen from './components/upload/uploadScreen';
import ThanksScreen from './components/thanks/thanksScreen';
import WelcomeScreen from './components/welcome/welcomeScreen';
import i18next, { ON_LANGUAGE_CHANGED } from './react-i18next';

const CSS_VAR_PRIMARY_COLOR = '--primary';
const CSS_VAR_BACKGROUND_COLOR = '--background-color';
const HREF_APP_ICON = '#appIcon';
const HREF = 'href';

i18next.on(ON_LANGUAGE_CHANGED, () => {
  store.dispatch(actions.setThemeData());
});

const App = () => {
  const location = useLocation();

  const themeData = useSelector(getThemeData);
  document.documentElement.style.setProperty(
    CSS_VAR_BACKGROUND_COLOR,
    themeData.pagesBackgroundColor
  );
  document.documentElement.style.setProperty(
    CSS_VAR_PRIMARY_COLOR,
    themeData.primaryColor
  );
  document.querySelector(HREF_APP_ICON).setAttribute(HREF, themeData.favicon);

  return (
    <div className="app-container">
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route
            exact
            path="/"
            name="Welcome"
            render={(props) => <WelcomeScreen {...props} />}
          />
          <Route
            exact
            path="/survey"
            name="Survey"
            render={(props) => <SurveyScreen {...props} />}
          />
          <Route
            exact
            path="/upload"
            name="Upload"
            render={(props) => <UploadScreen {...props} />}
          />
          <Route
            exact
            path="/thanks"
            name="Thanks"
            render={(props) => <ThanksScreen {...props} />}
          />
          <Route
            exact
            path="/error"
            name="Error"
            render={(props) => <ErrorScreen {...props} />}
          />
        </Switch>
      </AnimatePresence>
    </div>
  );
};

export default App;
