import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './thanksScreen.css';
import Radium, { StyleRoot } from 'radium';
import { fadeInUp, zoomIn } from 'react-animations';
import { AssetsConstants } from '../../index';
import ProcessesEnum from '../../helpers/processesEnum';
import * as actions from '../../store/actions/rootActions';
import { useTranslation } from 'react-i18next';
import * as SurveyService from '../../services/surveyService';
import { getThemeData } from '../../store/selectors/themeSelector';
import { THANKS_INPUT_TYPE } from '../../helpers/constants';
import { motion } from 'framer-motion';
import MotionWrapper from '../motionWrapper';

const buttonVariants = {
  hidden: {
    opacity: 0,
    y: '100vh',
    overflow: 'hidden',
    position: 'fixed',
    height: 0,
    bottom: 0,
    width: '100%',
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.2 },
    height: 'auto',
    overflow: 'visible',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  exit: {
    y: '100vh',
    transition: { ease: 'easeInOut' },
    height: 0,
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
};

const styles = {
  zoomIn: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(zoomIn),
  },
  zoomInLoding: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(zoomIn, 'zoomInLoding'),
  },
  zoomInDone: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(zoomIn, 'zoomInDone'),
  },
  zoomInError: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(zoomIn, 'zoomInError'),
  },
  fadeInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUpDone: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUpDone'),
  },
  fadeInUpError: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUpError'),
  },
};

const ThanksScreen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reviewRedux = useSelector((state) => state.survey.review);
  const isOnline = useSelector((state) => state.connectivity.isOnline);
  const processes = useSelector((state) => state.connectivity.processes);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [status, setStatus] = useState('INITIAL');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showButtons, setShowButtons] = useState(true);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const themeData = useSelector(getThemeData);

  const isConnected = useRef(true);

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const android = /android/i.test(navigator.userAgent);

  const onFollowUp = useCallback(async () => {
    document.activeElement.blur();
    if (isConnected.current) {
      if (reviewRedux.survey_id) {
        if (email && isEmailValid) {
          setShowButtons(false);
          setStatus('LOADING');
          const error = await SurveyService.registerFollowUp(
            reviewRedux.survey_id,
            email,
            name,
            phoneNumber
          );
          if (error) {
            if (error !== 'Connectivity Issue') {
              setStatus('ERROR');
            }
          } else {
            setStatus('SUCCEEDED');
          }
        } else {
          setIsEmailValid(false);
        }
      } else {
        setShowButtons(false);
        setStatus('ERROR');
      }
    }
  }, [email, isEmailValid, phoneNumber, name, reviewRedux.survey_id]);

  useEffect(() => {
    isConnected.current = isOnline;
    if (!isOnline) {
      dispatch(actions.queueTheFollowUpProcesses(status));
    } else {
      if (processes.includes(ProcessesEnum.requestFollowUp)) {
        onFollowUp();
      }
    }
  }, [isOnline, dispatch, onFollowUp, processes, status]);

  useEffect(() => {
    if (android) {
      const initialScreenSize = window.innerHeight;
      window.addEventListener('resize', () =>
        onResizeAndroid(initialScreenSize)
      );
      return () => {
        window.removeEventListener('resize', () =>
          onResizeAndroid(initialScreenSize)
        );
      };
    }
    if (iOS && 'visualViewport' in window) {
      visualViewport.addEventListener('resize', onResizeIOS);

      return () => {
        visualViewport.removeEventListener('resize', onResizeIOS);
      };
    }
  }, [android, iOS]);

  const onResizeIOS = (event) => {
    const relativeDifferenceBetweenInnerHeightAndViewportHeight =
      (window.innerHeight - event.target.height) / window.innerHeight;

    relativeDifferenceBetweenInnerHeightAndViewportHeight > 0.1
      ? setIsKeyboardOpen(true)
      : setIsKeyboardOpen(false);
  };

  const onResizeAndroid = (initialScreenSize) => {
    if (initialScreenSize > window.innerHeight) {
      setIsKeyboardOpen(true);
    } else {
      setIsKeyboardOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      document.activeElement.blur();
    }
  };

  const emailValidator = (email) => {
    setEmail(email);
    const EMAIL_REGEXP =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    setIsEmailValid(EMAIL_REGEXP.test(email));
  };

  const phoneNumberValidator = (event) => {
    const { max, value } = event.target;
    if (value.length <= Number(max)) {
      setPhoneNumber(value);
    }
  };

  const getContentByStatus = () => {
    switch (status) {
      case 'INITIAL':
        return (
          <div id="initial">
            <h2>
              <b>{t('thanks_part1')}</b>
              <br />
              {themeData.followUpScreen.secondTitle.text}
            </h2>
            <p>{t('thanks_part3')}</p>
          </div>
        );
      case 'LOADING':
        return (
          <div id="loading">
            <h2>
              <b>{t('thanks_part1')}</b>
              <br />
              {themeData.followUpScreen.secondTitle.text}
            </h2>
            <img
              src={AssetsConstants.LOADING_GIF}
              alt=""
              style={styles.zoomInLoding}
            />
          </div>
        );
      case 'SUCCEEDED':
        return (
          <div id="succeeded">
            <h2 style={styles.fadeInUp}>
              <b>{themeData.followUpScreen.redirect.part1Text}</b>
              <br />
            </h2>
            <p style={styles.fadeInUp}>
              {themeData.followUpScreen.redirect.part2Text}
            </p>
            <img
              src={AssetsConstants.CHECK_ICON}
              alt=""
              style={styles.zoomIn}
            />
          </div>
        );
      case 'DONE':
        return (
          <div id="done">
            <h2 style={styles.fadeInUpDone}>
              <b>{t('finalthanks_part1')}</b>
              <br />
              {t('finalthanks_part2')}
            </h2>
            <img
              src={AssetsConstants.CHECK_ICON}
              alt=""
              style={styles.zoomInDone}
            />
          </div>
        );
      case 'ERROR':
        return (
          <div id="error">
            <h2 style={styles.fadeInUpError}>
              <b>{t('followup_error_title')}</b>
              <br />
            </h2>
            <p style={styles.fadeInUpError}>{t('followup_error_message')}</p>
            <img
              src={AssetsConstants.ERROR_ICON}
              alt=""
              style={styles.zoomInError}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const onDone = () => {
    document.activeElement.blur();
    setShowButtons(false);
    setStatus('DONE');
  };

  const getCorrespondentInput = (inputType) => {
    switch (inputType) {
      case THANKS_INPUT_TYPE.name:
        return {
          type: 'text',
          placeholder: t('followup_name_placeholder'),
          className: 'form-control',
          value: name,
          onChange: (event) => setName(event.target.value),
          id: 'name',
        };
      case THANKS_INPUT_TYPE.email:
        return {
          type: 'email',
          placeholder: t('followup_email_placeholder'),
          id: 'email',
          className: `form-control ${!isEmailValid ? 'invalid-input' : null}`,
          value: email,
          onChange: (event) => emailValidator(event.target.value),
        };
      case THANKS_INPUT_TYPE.phone:
        return {
          type: 'number',
          max: '20',
          placeholder: t('followup_phone_number_placeholder'),
          className: 'form-control',
          value: phoneNumber,
          onChange: (event) => phoneNumberValidator(event),
          id: 'phoneNumber',
        };
      default:
        break;
    }
  };

  const inputsData = [
    THANKS_INPUT_TYPE.email,
    THANKS_INPUT_TYPE.name,
    THANKS_INPUT_TYPE.phone,
  ].map((inputType) => getCorrespondentInput(inputType));

  return (
    <>
      <MotionWrapper>
        <StyleRoot>
          <div className="thanks-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    src={themeData.followUpScreen.icon}
                    alt=""
                    width="220px"
                  />
                  {getContentByStatus()}
                </div>
              </div>
              {status === 'INITIAL' && (
                <div className="row m-0-btn-10-rem">
                  <div className="col-12 pad-0">
                    {inputsData.map((inputData, index) => {
                      return (
                        <input
                          {...inputData}
                          autoComplete="off"
                          onKeyDown={handleKeyDown}
                          key={`thanksInput-${index}`}
                        ></input>
                      );
                    })}
                  </div>
                  {!isEmailValid && (
                    <span style={{ color: 'red' }}>
                      {email
                        ? t('followup_invalid_email')
                        : t('followup_email_required')}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </StyleRoot>
      </MotionWrapper>
      {showButtons && !isKeyboardOpen && (
        <motion.div
          className="fub"
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="follow-up-bottom">
            <div className="col-12 text-center">
              <button
                type="button"
                className="btn follow-up-btn btn-lg start-button follow-up-request-btn"
                onClick={onFollowUp}
              >
                {themeData.followUpScreen.followUpButton.text}
              </button>
              <button
                type="button"
                className="btn follow-up-btn btn-lg btn-line"
                onClick={onDone}
              >
                {t('done_button')}
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ThanksScreen;
