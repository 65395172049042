// Gifs
const PRELOAD_GIF = require('../assets/preload.gif').default;
const DEFAULT_UPLOADING_GIF =
  require('../assets/default_theme_upload.gif').default;
const MY_TEAM_INSIGHTS_THEME_UPLOADING_GIF =
  require('../assets/my_team_insights_theme_upload.gif').default;
const LOADING_GIF = require('../assets/load-green.gif').default;
// Icons
const BREAKFAST_ICON = require('../assets/breakfast.png').default;
const LUNCH_ICON = require('../assets/lunch.png').default;
const SNACK_ICON = require('../assets/snack.png').default;
const DINNER_ICON = require('../assets/dinner.png').default;
const CHECK_ICON = require('../assets/check.png').default;
const ERROR_ICON = require('../assets/error-icon.png').default;
const CONNECTION_ICON = require('../assets/no-connection.png').default;
const DEFAULT_THEME_FAV_ICON =
  require('../assets/default_theme_fav_icon.png').default;
const MY_TEAM_INSIGHTS_THEME_FAV_ICON =
  require('../assets/my_team_insights_theme_fav_icon.png').default;
// Logos
const DEFAULT_THEME_LOGO = require('../assets/default_theme_logo.png').default;
const MY_TEAM_INSIGHTS_THEME_LOGO =
  require('../assets/my_team_insights_theme_logo.png').default;
// Others
const ERROR_IMAGE = require('../assets/error.png').default;
const DEFAULT_THEME_THANKS_IMAGE =
  require('../assets/default_theme_thanks.png').default;
const MY_TEAM_INSIGHTS_THEME_THANKS_IMAGE =
  require('../assets/my_team_insights_theme_thanks.png').default;

export {
  BREAKFAST_ICON,
  CHECK_ICON,
  CONNECTION_ICON,
  DINNER_ICON,
  ERROR_ICON,
  ERROR_IMAGE,
  LOADING_GIF,
  LUNCH_ICON,
  DEFAULT_THEME_LOGO,
  MY_TEAM_INSIGHTS_THEME_LOGO,
  PRELOAD_GIF,
  SNACK_ICON,
  DEFAULT_THEME_THANKS_IMAGE,
  MY_TEAM_INSIGHTS_THEME_THANKS_IMAGE,
  DEFAULT_UPLOADING_GIF,
  MY_TEAM_INSIGHTS_THEME_UPLOADING_GIF,
  DEFAULT_THEME_FAV_ICON,
  MY_TEAM_INSIGHTS_THEME_FAV_ICON,
};
