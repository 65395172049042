import { SAVE_TOKENS, CLEAR_TOKENS } from '../actions/actionTypes';

const initialState = {
  token: null,
  expiration: null,
  refreshToken: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOKENS:
      return {
        ...state,
        ...{
          token: action.token,
          expiration: action.expiration,
          refreshToken: action.refreshToken,
        },
      };
    case CLEAR_TOKENS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
