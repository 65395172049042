import React, { useEffect, useState } from 'react';
import './errorScreen.css';
import MotionWrapper from '../motionWrapper';
import { AssetsConstants } from '../../index';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ErrorScreen = (props) => {
  const { t } = useTranslation();
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const title = props.location.state?.errorTitle;
    const message = props.location.state?.errorMessage;
    if (title) {
      setErrorTitle(title);
    }
    if (message) {
      setErrorMessage(message);
    }
  }, [props.location.state?.errorTitle, props.location.state?.errorMessage]);

  return (
    <MotionWrapper>
      <div className="error-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <img src={AssetsConstants.ERROR_IMAGE} alt="" />
              <h2>{errorTitle || t('error_title')}</h2>
              <p>{errorMessage || t('error_message')}</p>
            </div>
          </div>
        </div>
      </div>
    </MotionWrapper>
  );
};

ErrorScreen.defaultProps = {
  location: { state: { errorTitle: '', errorMessage: '' } },
};

ErrorScreen.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      errorTitle: PropTypes.string.isRequired,
      errorMessage: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ErrorScreen;
