import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './welcomeScreen.css';
import Radium, { StyleRoot } from 'radium';
import useQuery from '../../hooks/useQuery';
import { AssetsConstants, browserHistory } from '../../index';
import ProcessesEnum from '../../helpers/processesEnum';
import * as actions from '../../store/actions/rootActions';
import { useTranslation } from 'react-i18next';
import { bounceIn, pulse, zoomOut } from 'react-animations';
import {
  getThemeData,
  getThemeType,
} from '../../store/selectors/themeSelector';
import PropTypes from 'prop-types';
import LanguageSelector from '../../hoc/languageSelector/languageSelector';
import { THEMES_TYPE } from '../../helpers/constants';

const styles = {
  bounceIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceIn, 'bounceIn'),
  },
  bounceInLogo: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceIn, 'bounceInLogo'),
  },
  pulseLoop: {
    animation: 'x 1s infinite',
    animationName: Radium.keyframes(pulse, 'pulseLoop'),
    color: 'white',
  },
  zoomOut: {
    animation: 'x 1s',
    animationName: Radium.keyframes(zoomOut, 'zoomOut'),
  },
};

const WelcomeScreen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const survey = useSelector((state) => state.survey.survey);
  const isOnline = useSelector((state) => state.connectivity.isOnline);
  const processes = useSelector((state) => state.connectivity.processes);
  const isLoading = useSelector((state) => state.loading.isLoadingLogin);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [isLogoChecked, setIsLogoChecked] = useState(false);
  const query = useQuery();
  const themeData = useSelector(getThemeData);
  const themeType = useSelector(getThemeType);
  const [logo, setLogo] = useState(themeData.splashScreen.icon);

  const isConnected = useRef(true);

  useEffect(() => {
    isConnected.current = isOnline;
  }, [isOnline]);

  useEffect(() => {
    setLogo(themeData.splashScreen.icon);
  }, [themeData.splashScreen.icon]);

  const decodeBase64Token = useCallback(() => {
    try {
      return window.atob(query.get('token'));
    } catch (error) {
      console.log(error);
      return null;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    try {
      const decodedToken = decodeBase64Token();
      if (decodedToken) {
        dispatch(actions.login(decodedToken));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, decodeBase64Token]);

  useEffect(() => {
    if (!isLoading) {
      if (survey?.logos.length > 0) {
        setLogo(survey.logos[0]);
      }
      setIsLogoChecked(true);
      setTimeout(() => {
        setInfoLoaded(true);
      }, 900);
    }
  }, [isLoading, survey?.logos]);

  useEffect(() => {
    if (!isOnline) {
      dispatch(actions.queueTheWelcomeProcesses(decodeBase64Token()));
    } else {
      if (processes.includes(ProcessesEnum.login)) {
        dispatch(actions.login(decodeBase64Token()));
      } else if (processes.includes(ProcessesEnum.getSurvey)) {
        dispatch(actions.getSurvey());
      }
    }
  }, [isOnline, dispatch, processes, decodeBase64Token]);

  const onStart = () => {
    browserHistory.push('/survey');
  };

  return (
    <>
      <StyleRoot>
        <div className="splash-wrapper">
          <div className="container">
            <div className="row">
              {!infoLoaded ? (
                <div className="col-12 text-center animation-container">
                  <img
                    src={AssetsConstants.PRELOAD_GIF}
                    alt=""
                    style={[
                      { width: '100%', height: 'auto' },
                      isLogoChecked ? styles.zoomOut : null,
                    ]}
                  />
                </div>
              ) : (
                <div className="col-12 text-center">
                  <div
                    className="logo"
                    style={
                      logoLoaded ? styles.bounceInLogo : { display: 'none' }
                    }
                  >
                    <img src={logo} alt="" onLoad={() => setLogoLoaded(true)} />
                  </div>
                  {logoLoaded && (
                    <div style={styles.bounceIn}>
                      <h2>{themeData.welcomeScreen.title.text}</h2>
                      <button
                        type="button"
                        className="btn btn-lg start-button"
                        style={styles.pulseLoop}
                        onClick={() => onStart()}
                      >
                        {t('start_button')}
                      </button>
                      <p>
                        <i>{t('powered_by')}</i>
                      </p>
                      {themeType === THEMES_TYPE.myTeamInsights && (
                        <div className="centered-logo-container">
                          <LanguageSelector />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </StyleRoot>
    </>
  );
};

WelcomeScreen.defaultProps = {
  history: { push: () => {} },
};

WelcomeScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default WelcomeScreen;
