import { SET_IS_LOADING_LOGIN } from '../actions/actionTypes';

const initialState = {
  isLoadingLogin: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING_LOGIN:
      return {
        ...state,
        ...{ isLoadingLogin: action.isLoadingLogin },
      };
    default:
      return state;
  }
};

export default reducer;
