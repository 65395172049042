export default class ConcreteTheme {
  constructor(
    favIcon,
    pagesBackgroundColor,
    splashScreenIcon,
    primaryColor,
    welcomeScreenTitle,
    questionsScreenTitleColor,
    questionsScreenDetailsButtonText,
    questionsScreenSuggestionTitleText,
    questionsScreenSuggestionBoxBackgroundColor,
    questionsScreenSuggestionTitleColor,
    questionsScreenSuggestionPlaceholder,
    uploadScreenGif,
    followUpScreenIcon,
    followUpScreenSecondTitleText,
    followUpScreenFollowUpButtonText,
    followupPart1Text,
    followupPart2Text
  ) {
    this.initialize();

    this.favicon = favIcon;
    this.pagesBackgroundColor = pagesBackgroundColor;

    this.splashScreen.icon = splashScreenIcon;

    this.primaryColor = primaryColor;

    this.welcomeScreen.title.text = welcomeScreenTitle;

    this.questionsScreen.title.color = questionsScreenTitleColor;
    this.questionsScreen.details.button.text = questionsScreenDetailsButtonText;
    this.questionsScreen.suggestion.title.text =
      questionsScreenSuggestionTitleText;
    this.questionsScreen.suggestion.box.backgroundColor =
      questionsScreenSuggestionBoxBackgroundColor;
    this.questionsScreen.suggestion.title.color =
      questionsScreenSuggestionTitleColor;
    this.questionsScreen.suggestion.placeholder =
      questionsScreenSuggestionPlaceholder;

    this.uploadScreen.gif = uploadScreenGif;

    this.followUpScreen.icon = followUpScreenIcon;
    this.followUpScreen.secondTitle.text = followUpScreenSecondTitleText;
    this.followUpScreen.followUpButton.text = followUpScreenFollowUpButtonText;
    this.followUpScreen.redirect.part1Text = followupPart1Text;
    this.followUpScreen.redirect.part2Text = followupPart2Text;
  }

  initialize() {
    this.favicon = '';
    this.pagesBackgroundColor = '';

    this.splashScreen = { icon: '' };

    this.primaryColor = '';

    this.welcomeScreen = {
      title: {
        text: '',
      },
    };

    this.questionsScreen = {
      title: {
        color: '',
      },
      details: {
        button: {
          text: '',
        },
        placeholder: '',
      },
      suggestion: {
        box: {
          backgroundColor: '',
        },
        title: {
          text: '',
          color: '',
        },
        placeholder: '',
      },
    };

    this.uploadScreen = { gif: '' };

    this.followUpScreen = {
      icon: '',
      secondTitle: {
        text: '',
      },
      followUpButton: {
        text: '',
      },
      redirect: {
        part1Text: '',
        part2Text: '',
      },
    };
  }
}
