import axios from 'axios';
import Config from '../config/config';
import { browserHistory } from '../index';

const axiosInstance = axios.create({
  baseURL: Config.backendUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    grant_type: 'client_credentials',
  },
  timeout: 20000,
});

axiosInstance.interceptors.request.use((req) => {
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.error('Error: ', err);
    const { response = {} } = err;
    const { status = 504 } = response;

    if (status >= 400 && status < 500) {
      switch (status) {
        case 408:
          throw new Error('Timeout Error!');
        default: {
          const url = response?.config?.url;
          if (url && !url.includes('images') && !url.includes('subscription')) {
            browserHistory.push('/error');
          } else {
            throw new Error('Something Went Wrong!');
          }
          return;
        }
      }
    }

    if (status >= 500) {
      throw new Error('Timeout Error!');
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
