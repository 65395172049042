import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Translations, { SUPPORTED_LANGUAGES } from './locales';
import LanguageDetector from 'i18next-browser-languagedetector';

const ON_LANGUAGE_CHANGED = 'languageChanged';
const STORAGE = 'localStorage';

if (!i18n.isInitialized) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: Translations,
      fallbackLng: SUPPORTED_LANGUAGES.ENGLISH,
      supportedLngs: Object.values(SUPPORTED_LANGUAGES),
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [STORAGE, 'navigator'],
        caches: [STORAGE],
        convertDetectedLanguage: (lng) => lng.slice(0, 2).toLocaleLowerCase(),
      },
    });
}

export { i18n as default, ON_LANGUAGE_CHANGED };
