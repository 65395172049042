import React, { useEffect } from 'react';
import './connectivityChecker.css';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../store/actions/rootActions';
import { AssetsConstants } from '../../index';
import { useTranslation } from 'react-i18next';

const alertVariants = {
  hidden: {
    opacity: 0,
    y: '-100vh',
    position: 'fixed',
    height: 0,
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1000,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.2 },
    height: 'auto',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1000,
  },
  exit: {
    y: '-100vh',
    transition: { ease: 'easeInOut' },
    height: 0,
    right: 0,
    left: 0,
    position: 'fixed',
    top: 0,
    zIndex: 1000,
  },
};

const ConnectivityChecker = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOnline = useSelector((state) => state.connectivity.isOnline);

  // Register the event listeners
  useEffect(() => {
    const setOnline = () => {
      dispatch(actions.setConnectionStatus(true));
    };
    const setOffline = () => {
      dispatch(actions.setConnectionStatus(false));
    };

    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, [dispatch]);

  return (
    <>
      {!isOnline && (
        <motion.div
          className="connectivity-alert"
          variants={alertVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div
            className="alert connection alert-danger alert-dismissible"
            role="alert"
          >
            <div>
              <img src={AssetsConstants.CONNECTION_ICON} />
              {t('error_no_connection')}
              <span className="dotting"></span>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ConnectivityChecker;
