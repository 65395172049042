import React from 'react';
import './ratingStars.css';
import { flip } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import PropTypes from 'prop-types';

const styles = {
  flip: {
    animation: 'x 1s',
    animationName: Radium.keyframes(flip),
  },
};

const ratingNumbers = [1, 2, 3, 4, 5];

const RatingStars = (props) => {
  const { rating, onRatingChange } = props;

  const stars = ratingNumbers.map((ratingNumber) => {
    return (
      <i
        key={`RatingStars-${ratingNumber}-${Math.random()}`}
        className={`fa-star ${rating >= ratingNumber ? 'fas' : 'fas far'}`}
        style={rating === ratingNumber ? styles.flip : null}
        onClick={() => onRatingChange(ratingNumber)}
      ></i>
    );
  });

  return <StyleRoot className="stars">{stars}</StyleRoot>;
};

RatingStars.defaultProps = {
  rating: 0,
  onRatingChange: () => {},
};

RatingStars.propTypes = {
  rating: PropTypes.number.isRequired,
  onRatingChange: PropTypes.func.isRequired,
};

export default React.memo(
  RatingStars,
  (prevProps, nextProps) => prevProps.rating === nextProps.rating
);
