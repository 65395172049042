import * as actionTypes from './actionTypes';

export const login = (token) => {
  return {
    type: actionTypes.LOGIN,
    token: token,
  };
};

export const saveTokens = (token, expiration, refreshToken) => {
  return {
    type: actionTypes.SAVE_TOKENS,
    token: token,
    expiration: expiration,
    refreshToken: refreshToken,
  };
};

export const clearTokens = () => {
  return {
    type: actionTypes.CLEAR_TOKENS,
  };
};
