import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './uploadScreen.css';
import { motion } from 'framer-motion';
import Radium, { StyleRoot } from 'radium';
import MotionWrapper from '../motionWrapper';
import ProcessesEnum from '../../helpers/processesEnum';
import * as actions from '../../store/actions/rootActions';
import { useTranslation } from 'react-i18next';
import { fadeInUp, flash, flipInX } from 'react-animations';
import * as SurveyService from '../../services/surveyService';
import PropTypes from 'prop-types';
import { getThemeData } from '../../store/selectors/themeSelector';

const buttonVariants = {
  hidden: {
    opacity: 0,
    y: '100vh',
    overflow: 'hidden',
    position: 'fixed',
    height: 0,
    bottom: 0,
    width: '100%',
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.2 },
    height: 'auto',
    overflow: 'visible',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  exit: {
    y: '100vh',
    transition: { ease: 'easeInOut' },
    height: 0,
    overflow: 'hidden',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
};

const styles = {
  fadeInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  flash: {
    animation: 'x 3.5s infinite',
    animationName: Radium.keyframes(flash, 'flash'),
  },
  flipInX: {
    animation: 'x 1.5s',
    animationName: Radium.keyframes(flipInX, 'flipInX'),
  },
};

const EMPTY_MESSAGE = '';

const UploadScreen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reviewRedux = useSelector((state) => state.survey.review);
  const isOnline = useSelector((state) => state.connectivity.isOnline);
  const processes = useSelector((state) => state.connectivity.processes);
  const pendingPhotosRedux = useSelector((state) => state.survey.pendingPhotos);
  const [photos, setPhotos] = useState([]);
  const [review, setReview] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);
  const [isUploadingSurvey, setIsUploadingSurvey] = useState(false);
  const [hasUploadedSurvey, setHasUploadedSurvey] = useState(false);
  const [reviewUploaded, setReviewUploaded] = useState(false);
  const [pendingPhotosReseted, setPendingPhotosReseted] = useState(false);
  const themeData = useSelector(getThemeData);

  const isConnected = useRef(true);

  const initBeforeUnLoad = (photos) => {
    window.onbeforeunload = (event) => {
      savePendingPhotos(photos);
    };
  };

  const savePendingPhotos = (photos) => {
    const pendingPhotos = photos.filter(
      (photo) => photo.uploadingStatus !== 'UPLOADED'
    );
    const idPhotos = pendingPhotos.map(({ id }) => id);
    dispatch(actions.savePendingPhotos(idPhotos));
  };

  useEffect(() => {
    loadData();
    initBeforeUnLoad(photos);
    window.addEventListener('popstate', () => {
      props.history.go(1);
    });
    return () =>
      window.removeEventListener('popstate', () => {
        props.history.go(1);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initBeforeUnLoad(photos);
    // eslint-disable-next-line
  }, [photos]);

  useEffect(() => {
    if (dataLoaded) {
      uploadData();
    }
    // eslint-disable-next-line
  }, [dataLoaded]);

  useEffect(() => {
    if (!isOnline) {
      setPendingPhotosReseted(false);
      const uploadedPhotos = photos.filter(
        (photo) => photo.uploadingStatus !== 'UPLOADED'
      );
      const photosId = uploadedPhotos.map(({ id }) => id);
      dispatch(actions.queueTheUploadProcesses(photosId));
    } else {
      if (
        processes.includes(ProcessesEnum.submitReview) ||
        processes.includes(ProcessesEnum.uploadPhotos)
      ) {
        isConnected.current = isOnline;
        resetPendingPhotosStatus();
      }
    }
    // eslint-disable-next-line
  }, [isOnline]);

  useEffect(() => {
    isConnected.current = isOnline;
  }, [isOnline]);

  useEffect(() => {
    if (pendingPhotosReseted) {
      uploadData();
    }
    // eslint-disable-next-line
  }, [pendingPhotosReseted]);

  const loadData = async () => {
    let surveyPhotos = props.location.state?.photos;
    try {
      surveyPhotos = surveyPhotos.map((photo) => {
        let status;
        if (pendingPhotosRedux && pendingPhotosRedux.length > 0) {
          if (pendingPhotosRedux.includes(photo.id)) {
            status = 'UPLOADING';
          } else {
            status = 'UPLOADED';
          }
        } else {
          status = 'UPLOADING';
        }
        return { ...photo, retries: 0, uploadingStatus: status };
      });
      if (reviewRedux) {
        await setReview(reviewRedux);
        if (photos) {
          await setPhotos(surveyPhotos);
        }

        cleanUploadingStatus();
        setIsUploadingSurvey(true);

        setDataLoaded(true);
      } else {
        props.history.push('/error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadData = async () => {
    const reviewValue = { ...review };
    if (reviewValue.survey_id) {
      submitPhotos(reviewValue.survey_id);
    } else {
      const reviewId = await SurveyService.submit(reviewValue);
      if (reviewId) {
        dispatch(actions.saveReview({ ...reviewRedux, survey_id: reviewId }));
        setReview({ ...review, survey_id: reviewId });
        setTimeout(() => {
          submitPhotos(reviewId);
        }, 1000);
      }
    }
  };

  const updatePhotoStatus = (status, index, retries) => {
    if (index >= 0) {
      const surveyPhotos = [...photos];
      surveyPhotos[index].uploadingStatus = status;
      surveyPhotos[index].retries = retries || surveyPhotos[index].retries;
      setPhotos(surveyPhotos);
    }
  };

  const resetPendingPhotosStatus = () => {
    let surveyPhotos = [...photos];
    surveyPhotos = surveyPhotos.map((photo) => {
      let status;
      if (pendingPhotosRedux.includes(photo.id)) {
        status = 'UPLOADING';
      } else {
        status = photo.uploadingStatus;
      }
      return { ...photo, uploadingStatus: status };
    });
    setPhotos(surveyPhotos);
    setPendingPhotosReseted(true);
  };

  const submitPhotos = async (reviewId) => {
    cleanUploadingStatus();
    setIsUploadingPhotos(true);

    setReviewUploaded(true);
    for (const [index, surveyPhoto] of photos.entries()) {
      if (!isConnected.current) {
        await stopPhotosUpload();
        break;
      } else {
        if (surveyPhoto.uploadingStatus !== 'UPLOADED') {
          const photoData = { ...surveyPhoto };
          const blobPhoto = await fetch(photoData.photo).then((r) => r.blob());
          photoData.photo = blobPhoto;
          const failedPhoto = await SurveyService.submitPhoto(
            reviewId,
            photoData
          );
          if (failedPhoto) {
            updatePhotoStatus('FAILED', index);
          } else {
            updatePhotoStatus('UPLOADED', index);
          }
        }
      }
    }

    cleanUploadingStatus();
    setHasUploadedSurvey(true);

    const result = photos.filter(
      (photo) => photo.uploadingStatus !== 'UPLOADED'
    );
    if (result.length === 0) {
      props.history.push('/thanks');
    } else {
      const photosLeft = photos.filter(
        (photo) => photo.uploadingStatus === 'UPLOADING'
      );
      if (photosLeft.length === 0) {
        setShowButton(true);
      }
    }
  };

  const stopPhotosUpload = async () => {
    for (const [index, surveyPhoto] of photos.entries()) {
      if (surveyPhoto.uploadingStatus !== 'UPLOADED') {
        updatePhotoStatus('FAILED', index);
      }
    }
  };

  const retryPhoto = async (surveyPhoto, index) => {
    cleanUploadingStatus();
    setIsUploadingPhotos(true);

    updatePhotoStatus('UPLOADING', index, surveyPhoto.retries + 1);
    const reviewValue = { ...review };
    const photoData = { ...surveyPhoto };
    const blobPhoto = await fetch(photoData.photo).then((r) => r.blob());
    photoData.photo = blobPhoto;
    const failedPhoto = await SurveyService.submitPhoto(
      reviewValue.survey_id,
      photoData
    );
    if (failedPhoto) {
      updatePhotoStatus(
        surveyPhoto.retries + 1 === 4 ? 'BLOCKED' : 'FAILED',
        index
      );
    } else {
      updatePhotoStatus('UPLOADED', index);
    }
    const result = photos.filter(
      (photo) => photo.uploadingStatus !== 'UPLOADED'
    );
    if (result.length === 0) {
      props.history.push('/thanks');
    } else {
      if (!showButton) {
        const photosLeft = photos.filter(
          (photo) => photo.uploadingStatus === 'UPLOADING'
        );
        if (photosLeft.length === 0) {
          setShowButton(true);
        }
      }
    }
  };

  const getStatusIcon = (photo, index) => {
    switch (photo.uploadingStatus) {
      case 'UPLOADING':
        return (
          <div
            className="spinner-border text-secondary f-right-icon"
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
        );
      case 'UPLOADED':
        return (
          <i
            className="fa fa-check-circle f-right-icon icon green"
            aria-hidden="true"
          ></i>
        );
      case 'FAILED':
        return (
          <i
            className="fas fa-redo-alt f-right-icon icon gray"
            onClick={() =>
              isConnected.current ? retryPhoto(photo, index) : null
            }
          ></i>
        );
      case 'BLOCKED':
        return (
          <i
            className="fas fa-times-circle f-right-icon icon red"
            aria-hidden="true"
          ></i>
        );
      default:
        break;
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'UPLOADING':
        return t('uploading_photo_message');
      case 'UPLOADED':
        return t('photo_uploaded_message');
      case 'FAILED':
        return t('photo_upload_failed_message');
      case 'BLOCKED':
        return t('retry_limit_message');
      default:
        break;
    }
  };

  const onFinish = () => {
    if (isConnected.current) {
      props.history.push('/thanks');
    }
  };

  const cleanUploadingStatus = useCallback(() => {
    setIsUploadingPhotos(false);
    setIsUploadingSurvey(false);
    setHasUploadedSurvey(false);
  }, []);

  const getUploadingStatus = useCallback(() => {
    if (isUploadingPhotos) return t('uploading_photos_message');

    if (isUploadingSurvey) return t('uploading_survey_message');

    if (hasUploadedSurvey) return t('survey_uploaded_message');

    return EMPTY_MESSAGE;
  }, [isUploadingPhotos, isUploadingSurvey, hasUploadedSurvey, t]);

  return (
    <>
      <MotionWrapper>
        <StyleRoot>
          <div className="upload-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <img
                    className="m-w-96"
                    src={themeData.uploadScreen.gif}
                    alt=""
                  />
                  <h2 style={styles.flash}>{getUploadingStatus()}</h2>
                </div>

                <div className="col-12 pad-0">
                  {reviewUploaded &&
                    photos.map((photo, index) => {
                      return (
                        <div
                          key={'item' + index.toString()}
                          className="content-box gallery m-btn-15"
                          style={styles.flipInX}
                        >
                          <div className="image">
                            <img src={photo.photo} alt="" />
                          </div>
                          <span>{getStatusLabel(photo.uploadingStatus)}</span>
                          {getStatusIcon(photo, index)}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>
      </MotionWrapper>
      {showButton && (
        <motion.div
          className="fb"
          variants={buttonVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <div className="finish-bottom">
            <div className="col-12 text-center">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={() => onFinish()}
              >
                {t('finish_button')}
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

UploadScreen.defaultProps = {
  history: { push: () => {}, go: () => {} },
  location: { state: { photos: [] } },
};

UploadScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      photos: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export default UploadScreen;
