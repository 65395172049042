import * as actionTypes from './actionTypes';

export const setError = (errorMessage) => {
  return {
    type: actionTypes.SET_ERROR,
    errorMessage: errorMessage,
  };
};

export const setErrorSucceeded = (errorMessage) => {
  return {
    type: actionTypes.SET_ERROR,
    errorMessage: errorMessage,
  };
};

export const clearError = () => {
  return {
    type: actionTypes.CLEAR_ERROR,
  };
};
