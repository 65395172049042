const DEFAULT_PRIMARY_COLOR = '#95D2D0';
const DEFAULT_BACKGROUND_COLOR = '#EDF4F3';
const DEFAULT_QUESTION_SCREEN_SUGGESTION_BOX_BACKGROUND_COLOR = '#e7e7e7';
const DEFAULT_QUESTION_SCREEN_TITLE_COLOR = DEFAULT_PRIMARY_COLOR;
const DEFAULT_QUESTION_SCREEN_SUGGESTION_TITLE_COLOR = DEFAULT_PRIMARY_COLOR;
const LINKEDIN_PRIMARY_COLOR = '#0966c2';
const LINKEDIN_BACKGROUND_COLOR = '#f5f5f5';
const LINKEDIN_QUESTION_SCREEN_TITLES_COLOR = '#242322';
const LINKEDIN_QUESTION_SCREEN_TITLE_COLOR =
  LINKEDIN_QUESTION_SCREEN_TITLES_COLOR;
const LINKEDIN_QUESTION_SCREEN_SUGGESTION_BOX_BACKGROUND_COLOR = '#E4E4E4';
const LINKEDIN_QUESTION_SCREEN_SUGGESTION_TITLE_COLOR =
  LINKEDIN_QUESTION_SCREEN_TITLES_COLOR;

const colors = {
  defaultBackgroundColor: DEFAULT_BACKGROUND_COLOR,
  defaultPrimaryColor: DEFAULT_PRIMARY_COLOR,
  defaultQuestionsScreenTitleColor: DEFAULT_QUESTION_SCREEN_TITLE_COLOR,
  defaultQuestionsScreenSuggestionBoxBackgroundColor:
    DEFAULT_QUESTION_SCREEN_SUGGESTION_BOX_BACKGROUND_COLOR,
  defaultQuestionsScreenSuggestionTitleColor:
    DEFAULT_QUESTION_SCREEN_SUGGESTION_TITLE_COLOR,

  linkedinThemeBackgroundColor: LINKEDIN_BACKGROUND_COLOR,
  linkedinPrimaryColor: LINKEDIN_PRIMARY_COLOR,
  linkedinQuestionsScreenTitleColor: LINKEDIN_QUESTION_SCREEN_TITLE_COLOR,
  linkedinQuestionsScreenSuggestionBoxBackgroundColor:
    LINKEDIN_QUESTION_SCREEN_SUGGESTION_BOX_BACKGROUND_COLOR,
  linkedinQuestionsScreenSuggestionTitleColor:
    LINKEDIN_QUESTION_SCREEN_SUGGESTION_TITLE_COLOR,
};

export default colors;
